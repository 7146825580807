import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';

const Chart = ({ data }) => {
  return (
    <ResponsiveContainer className='min-h-[300px] md:min-h-[400px] w-full flex flex-row items-center justify-center bg-[#fdfdfd] rounded-xl pt-5 pr-5 text-sm md:text-lg'>
      <BarChart data={data} className='-ml-10 md:-ml-5'>
        <XAxis dataKey='title' fontSize='10px' className='text-xs' />
        <YAxis />
        <Tooltip
          contentStyle={{
            maxWidth: '200px',
            overflow: 'hidden',
            textAlign: 'left',
            objectFit: 'contain',
            fontSize: '15px',
          }}
        />
        <Bar dataKey='registered' fill='#82ca9d' />
        <Bar dataKey='attended' fill='#8884d8' />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
