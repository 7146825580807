import { toast } from 'sonner';
import { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import { Flex, Select, Box, AlertDialog, Button } from '@radix-ui/themes';

import { checkSteamAttendance } from '../../helper/attendance';

function SteamScanner({ eventData }) {
  const [showAlert, setShowAlert] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(undefined);

  const handleEventSelectDropdownChange = (e) => {
    // console.log(e);
    if (!e) {
      //this is to turn of the camera if nothing is selected
      setSelectedEvent(undefined);
      return;
    }
    setSelectedEvent(e);
  };

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      gap='5'
      width='100%'
      height='100%'
    >
      {eventData && (
        <Select.Root
          size='3'
          defaultValue={undefined}
          name='eventSelection'
          onValueChange={handleEventSelectDropdownChange}
        >
          <Select.Trigger color='#566573' className='px-6 h-10' />
          <Select.Content color='#566573' className='w-auto'>
            <Select.Group className='w-auto'>
              <Select.Item className='text-sm w-auto' value={undefined}>
                Select Steam Sub-Event
              </Select.Item>
              <Select.Item className='text-sm w-auto' value={'mainentrance'}>
                Main Entrance
              </Select.Item>
              {eventData.map((eventName, key) => (
                <Select.Item
                  className='text-sm w-auto'
                  value={eventName.id}
                  key={key}
                >
                  {eventName.title}
                </Select.Item>
              ))}
            </Select.Group>
          </Select.Content>
        </Select.Root>
      )}

      <Box className='w-full md:w-[50%]'>
        <Scanner
          options={{ delayBetweenScanSuccess: 3000 }}
          styles={{
            container: {
              border: 'none',
            },
            finderBorder: 10,
          }}
          enabled={selectedEvent !== undefined}
          onResult={async (hashedId, result) => {
            const [attendanceResult, message, userDetails] =
              await checkSteamAttendance(hashedId, selectedEvent);

            setUserDetails(userDetails);

            if (attendanceResult === true) {
              toast.success(message);
              setShowAlert(true);
              // console.log(message);
            } else {
              toast.error(message);
              console.error(message);
            }
          }}
          onError={(error) => console.error(error?.message)}
        />
      </Box>

      <AlertDialog.Root open={showAlert}>
        <AlertDialog.Content className='w-auto p-10 flex flex-col align-center gap-5'>
          <AlertDialog.Title className='text-center'>
            {eventData && eventData[selectedEvent]?.title}
          </AlertDialog.Title>
          <AlertDialog.Description asChild>
            <Flex direction='column' align='start' gap='5'>
              <p>
                <strong>Name: </strong>
                {userDetails.name}
              </p>
              <p>
                <strong>School: </strong>
                {userDetails.schoolName}
              </p>
              <p>
                <strong>Grade: </strong>
                {userDetails.grade}
              </p>
              <p>
                <strong>Emirates ID: </strong>
                {userDetails.emiratesId}
              </p>
            </Flex>
          </AlertDialog.Description>
          <Flex gap='3' mt='5' justify='center'>
            <AlertDialog.Cancel>
              <Button
                variant='solid'
                onClick={() => setShowAlert(false)}
                className='text-black px-9 bg-[#fdfdfd] rounded-lg cursor-pointer'
              >
                Close
              </Button>
            </AlertDialog.Cancel>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </Flex>
  );
}

export default SteamScanner;
