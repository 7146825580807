import { useEffect, useState } from 'react';
import { Card, Grid, Flex } from '@radix-ui/themes';

import Chart from './Chart';
import CategoriesTable from './CategoriesTable';

const Dashboard = ({ userData }) => {
  const [totalRegistered, setTotalRegistered] = useState(0);
  const [totalAttended, setTotalAttended] = useState(0);

  useEffect(() => {
    let totalRegistered = 0;
    let totalAttended = 0;

    userData.forEach((user) => {
      totalRegistered += 1;
      totalAttended += user.attendance_done ? 1 : 0;
    });

    setTotalRegistered(totalRegistered);
    setTotalAttended(totalAttended);
  }, [userData]);

  const [data, setData] = useState([]);

  useEffect(() => {
    let localData = [
      {
        id: 'invited-speaker',
        title: 'Invited Speaker',
        registered: 0,
        attended: 0,
      },
      {
        id: 'panel-member',
        title: 'Panel Member',
        registered: 0,
        attended: 0,
      },
      {
        id: 'expert',
        title: 'Expert/Examiner/Collaborator',
        registered: 0,
        attended: 0,
      },
      {
        id: 'presenter-bpdc',
        title: 'Presenter - BPDC',
        registered: 0,
        attended: 0,
      },
      {
        id: 'presenter-external',
        title: 'Presenter - External',
        registered: 0,
        attended: 0,
      },
      {
        id: 'startup',
        title: 'Startup',
        registered: 0,
        attended: 0,
      },
      {
        id: 'visitor',
        title: 'Visitor',
        registered: 0,
        attended: 0,
      },
    ];

    localData.map((category) => {
      userData.map((user) => {
        if (user?.category === category.title) {
          category.registered++;
          if (user?.attendance_done === true) {
            category.attended++;
          }
        }
        return user;
      });
      return category;
    });

    setData(localData);
  }, [userData]);

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      width='100%'
      height='100%'
      gap={{ initial: '3', md: '5' }}
    >
      <Grid
        columns={{ initial: '1', md: '4' }}
        align='ceter'
        justify='center'
        gap={{ initial: '3', md: '5' }}
        width='100%'
      >
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Registrations</p>
          <p className='text-base md:text-lg font-semibold'>
            {totalRegistered}
          </p>
        </Card>
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Attended</p>
          <p className='text-base md:text-lg font-semibold'>{totalAttended}</p>
        </Card>
      </Grid>

      <Chart data={data} />

      <CategoriesTable userData={userData} data={data} />

      {/* <UsersTable userData={userData} /> */}
    </Flex>
  );
};

export default Dashboard;
