import { logEvent } from "firebase/analytics";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import Loading from "../components/Loading";
import { analytics } from "../services/firebase";
import { getDecryptedItem } from "../helper/helper_functions";

const UnprotectedRoute = ({ children }) => {
  let location = useLocation();
  const [session, setSession] = useState(false);
  const [loading, setLoading] = useState(true);
  logEvent(analytics, "website_start");

  useEffect(() => {
    const userName = getDecryptedItem("user_session");
    if (userName && userName !== "") {
      setSession(true);
    } else {
      setSession(false);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (session) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default UnprotectedRoute;
