import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { Container, Flex } from "@radix-ui/themes";
import { collection, onSnapshot, doc } from "firebase/firestore";

import { firestore } from "../services/firebase";
import UsersTable from "../components/steam/UsersTable";

const SteamEvent = () => {
  const { event } = useParams();
  const [users, setUsers] = useState([]);
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventsCollection = collection(firestore, "events");
        const eventDoc = doc(eventsCollection, "steam");

        const unsubscribeEvent = onSnapshot(eventDoc, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const localEventData = docSnapshot.data();

            setEventData(
              Object.entries(localEventData)
                .flatMap((innerArrays) => innerArrays.slice(1))
                .find((obj) => obj.id === event)
            );
          }
        });

        return () => {
          unsubscribeEvent();
        };
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const eventsCollection = collection(firestore, "steamregistrations");
        const unsubscribeUser = onSnapshot(
          eventsCollection,
          (querySnapshot) => {
            const localUserData = [];
            querySnapshot.forEach((doc) => {
              const userData = doc.data();
              if (userData.events && userData.events.includes(event)) {
                localUserData.push(userData);
              }
            });

            setUsers(
              localUserData.sort((a, b) => a.name.localeCompare(b.name))
            );
          }
        );

        return () => {
          unsubscribeUser();
        };
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    };

    fetchEventData();
    fetchUsers();
  }, [event]);

  return (
    <Container size="4" align="center" className="mb-5 md:mb-9">
      <Flex
        direction="column"
        align="center"
        gap={{ initial: "6", md: "9" }}
        className="text-center"
      >
        <h1 className="text-xl md:text-3xl font-bold">{eventData?.title}</h1>

        <UsersTable users={users} event={eventData} />
      </Flex>
    </Container>
  );
};

export default SteamEvent;
