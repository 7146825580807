import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Chart = ({ eventData }) => {
  const parsedEventData = eventData.map((entry) => {
    const { title: Event, reg_count: Students } = entry;
    return { id: "", Event, Students };
  });

  return (
    <ResponsiveContainer className="min-h-[300px] md:min-h-[400px] w-full bg-[#fdfdfd] rounded-xl pt-5 pr-5 text-sm md:text-lg">
      <LineChart data={parsedEventData} className="-ml-10 md:-ml-5">
        <XAxis dataKey="Event" fontSize="0px" />
        <YAxis />
        <Tooltip
          contentStyle={{
            maxWidth: "200px",
            overflow: "hidden",
            textAlign: "left",
            objectFit: "contain",
            fontSize: "15px",
          }}
        />
        <Line type="natural" dataKey="Students" stroke="#B6554F" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
