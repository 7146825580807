import { Link } from 'react-router-dom';
import { Table } from '@radix-ui/themes';

const EventsTable = ({ eventData }) => {
  return (
    <Table.Root size='3' variant='surface' className='w-full h-full'>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell className='text-sm'>
            Event
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell className='text-sm'>
            Students
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {eventData.map((event, index) => (
          <Table.Row key={index}>
            <Table.RowHeaderCell className='text-sm'>
              <Link to={event.id}>{event.title}</Link>
            </Table.RowHeaderCell>
            <Table.Cell className='text-sm'>{event.reg_count}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default EventsTable;
