import { useEffect, useState } from 'react';
import { Card, Grid, Flex } from '@radix-ui/themes';

import Chart from './Chart';
import EventsTable from './EventsTable';

const SteamDashboard = ({ eventData, userData }) => {
  const [studentRegCount, setStudentRegCount] = useState(0);
  const [totalRegCount, setTotalRegCount] = useState(0);
  const [guestCount, setGuestCount] = useState(0);
  const [attendance, setAttendance] = useState(0);

  useEffect(() => {
    let totalRegCount = 0;

    eventData.forEach((event) => {
      totalRegCount += event['reg_count'];
    });

    setTotalRegCount(totalRegCount);

    const studentReg = userData.length;
    setStudentRegCount(studentReg);

    let totalGuest = 0;

    userData.forEach((user) => {
      totalGuest += parseInt(user['guests']);
    });

    setGuestCount(totalGuest);

    let totalAttendance = 0;

    userData.forEach((user) => {
      if (user.attendance_done) {
        totalAttendance += user['attendance_done'].includes('mainentrance')
          ? 1 + parseInt(user['guests'])
          : 0;
      }
    });

    setAttendance(totalAttendance);
  }, [eventData, userData]);

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      width='100%'
      height='100%'
      gap={{ initial: '3', md: '5' }}
    >
      <Grid
        columns={{ initial: '1', md: '4' }}
        align='ceter'
        justify='center'
        gap={{ initial: '3', md: '5' }}
        width='100%'
      >
        {/* TODO change with data */}
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>
            Total Workshop Registrations
          </p>
          <p className='text-base md:text-lg font-semibold'>{totalRegCount}</p>
        </Card>
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Students</p>
          <p className='text-base md:text-lg font-semibold'>
            {studentRegCount}
          </p>
        </Card>
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Guests</p>
          <p className='text-base md:text-lg font-semibold'>{guestCount}</p>
        </Card>
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>
            {'Attendance (Inc. Guests)'}
          </p>
          <p className='text-base md:text-lg font-semibold'>{attendance}</p>
        </Card>
      </Grid>

      <Chart eventData={eventData} />

      <EventsTable eventData={eventData} />
    </Flex>
  );
};

export default SteamDashboard;
