import { toast } from 'sonner';
import { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import { Flex, Box, AlertDialog, Button, Select } from '@radix-ui/themes';

import { checkJashnAttendance } from '../../helper/attendance';
import { toTitleCase } from '../../helper/helper_functions';

function JashnScanner({ userData }) {
  const [showAlert, setShowAlert] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [selectedDay, setSelectedDay] = useState(undefined);

  const handleDaySelectDropdownChange = (e) => {
    if (!e) {
      //this is to turn of the camera if nothing is selected
      setSelectedDay(undefined);
      return;
    }
    setSelectedDay(e);
  };

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      gap='5'
      width='100%'
      height='100%'
    >
      <Select.Root
        size='3'
        defaultValue={undefined}
        name='eventSelection'
        onValueChange={handleDaySelectDropdownChange}
      >
        <Select.Trigger color='#566573' className='px-6 h-10' />
        <Select.Content color='#566573' className='w-auto'>
          <Select.Group className='w-auto'>
            <Select.Item className='text-sm w-auto' value={undefined}>
              Select Jashn Day
            </Select.Item>
            <Select.Item className='text-sm w-auto' value={'24'}>
              24th
            </Select.Item>
            <Select.Item className='text-sm w-auto' value={'25'}>
              25th
            </Select.Item>
            <Select.Item className='text-sm w-auto' value={'26'}>
              26th
            </Select.Item>
            <Select.Item className='text-sm w-auto' value={'27'}>
              27th
            </Select.Item>
          </Select.Group>
        </Select.Content>
      </Select.Root>
      <Box className='w-full md:w-[50%]'>
        <Scanner
          options={{ delayBetweenScanSuccess: 1000 }}
          styles={{
            container: {
              border: 'none',
            },
            finderBorder: 10,
          }}
          enabled={selectedDay !== undefined}
          onResult={async (hashedId) => {
            const [attendanceResult, message, userDetails] =
              await checkJashnAttendance(hashedId, selectedDay);

            setUserDetails(userDetails);

            if (attendanceResult === true) {
              toast.success(message);
              setShowAlert(true);
              // console.log(message);
            } else {
              toast.error(message);
              console.error(message);
            }
          }}
          onError={(error) => console.error(error?.message)}
        />
      </Box>

      <AlertDialog.Root open={showAlert}>
        <AlertDialog.Content className='w-auto p-10 flex flex-col align-center gap-5'>
          <AlertDialog.Title className='text-center'>
            Visitor Details
          </AlertDialog.Title>
          <AlertDialog.Description asChild>
            <Flex direction='column' align='start' gap='5'>
              <p>
                <strong>Category: </strong>
                {toTitleCase(userDetails?.category?.toString())}
              </p>
              <p>
                <strong>Name: </strong>
                {userDetails?.name}
              </p>
              <p>
                <strong>Email: </strong>
                {userDetails?.email}
              </p>
              <p>
                <strong>Emirates ID: </strong>
                {userDetails?.emiratesId}
              </p>
              <p>
                <strong>University: </strong>
                {userDetails?.universityName}
              </p>
              <p>
                <strong>University ID: </strong>
                {userDetails?.uniId}
              </p>
              <p>
                <strong>Visiting Days: </strong>
                <p className='text-sm flex flex-row items-center gap-2'>
                  {userDetails?.selectedDays?.map((d) => (
                    <p>{d}th</p>
                  ))}
                </p>
              </p>
              <p>
                <strong>Attended Days: </strong>
                <p className='text-sm flex flex-row items-center gap-2'>
                  {userDetails?.attendance_done?.map((d) => (
                    <p>{d}th</p>
                  ))}
                </p>
              </p>
            </Flex>
          </AlertDialog.Description>
          <Flex gap='3' mt='5' justify='center'>
            <AlertDialog.Cancel>
              <Button
                variant='solid'
                onClick={() => setShowAlert(false)}
                className='text-black px-9 bg-[#fdfdfd] rounded-lg cursor-pointer'
              >
                Close
              </Button>
            </AlertDialog.Cancel>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </Flex>
  );
}

export default JashnScanner;
