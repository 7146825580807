import { useEffect, useState } from 'react';
import { Card, Grid, Flex } from '@radix-ui/themes';

import Chart from './Chart';
import RegistrationTypesTable from './RegistrationTypesTable';

const Dashboard = ({ userData }) => {
  const [totalRegistered, setTotalRegistered] = useState(0);
  const [totalAttended, setTotalAttended] = useState(0);

  useEffect(() => {
    let totalRegistered = 0;
    let totalAttended = 0;

    userData.forEach((user) => {
      if (user.registrationType === 'group') {
        totalRegistered += 3;
        totalAttended += user?.participantsDetails?.participantOneDetails
          ?.attendance_done
          ? 1
          : 0;
        totalAttended += user?.participantsDetails?.participantTwoDetails
          ?.attendance_done
          ? 1
          : 0;
        totalAttended += user?.participantsDetails?.participantThreeDetails
          ?.attendance_done
          ? 1
          : 0;
      } else {
        totalRegistered += 1;
        totalAttended += user?.attendance_done ? 1 : 0;
      }
    });

    setTotalRegistered(totalRegistered);
    setTotalAttended(totalAttended);
  }, [userData]);

  const [data, setData] = useState([]);

  useEffect(() => {
    let localData = [
      {
        id: 'individual',
        title: 'Individual',
        registered: 0,
        attended: 0,
      },
      {
        id: 'group',
        title: 'Group',
        registered: 0,
        attended: 0,
      },
    ];

    localData.map((category) => {
      userData.map((user) => {
        if (
          user?.registrationType?.toLowerCase() ===
          category.title?.toLowerCase()
        ) {
          if (user?.registrationType === 'group') {
            category.registered += 3;
          } else {
            category.registered++;
          }
          if (user?.registrationType === 'group') {
            if (
              user?.participantsDetails?.participantOneDetails
                ?.attendance_done === true
            ) {
              category.attended++;
            }
            if (
              user?.participantsDetails?.participantTwoDetails
                ?.attendance_done === true
            ) {
              category.attended++;
            }
            if (
              user?.participantsDetails?.participantThreeDetails
                ?.attendance_done === true
            ) {
              category.attended++;
            }
          } else if (user?.attendance_done === true) {
            category.attended++;
          }
        }
        return user;
      });
      return category;
    });

    setData(localData);
  }, [userData]);

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      width='100%'
      height='100%'
      gap={{ initial: '3', md: '5' }}
    >
      <Grid
        columns={{ initial: '1', md: '4' }}
        align='ceter'
        justify='center'
        gap={{ initial: '3', md: '5' }}
        width='100%'
      >
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Registrations</p>
          <p className='text-base md:text-lg font-semibold'>
            {totalRegistered}
          </p>
        </Card>
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Attended</p>
          <p className='text-base md:text-lg font-semibold'>{totalAttended}</p>
        </Card>
      </Grid>

      <Chart data={data} />

      <RegistrationTypesTable userData={userData} data={data} />

      {/* <UsersTable userData={userData} /> */}
    </Flex>
  );
};

export default Dashboard;
