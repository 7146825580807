import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';
import { collection, onSnapshot } from 'firebase/firestore';

import { firestore } from '../services/firebase';
import UsersTable from '../components/qbd/UsersTable';

const QbDRegistrationTypes = () => {
  let localData = [
    {
      id: 'individual',
      title: 'Individual',
    },
    {
      id: 'group',
      title: 'Group',
    },
  ];
  const { registrationType } = useParams();
  const [users, setUsers] = useState([]);
  const parsedRegistrationType = localData.find(
    (registrationTypeObject) => registrationTypeObject.id === registrationType
  )?.title;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(firestore, 'registrations/2024/qbd');
        const unsubscribeUser = onSnapshot(usersCollection, (querySnapshot) => {
          const localUserData = [];
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            if (
              userData.registrationType?.toLowerCase() ===
              parsedRegistrationType?.toLowerCase()
            ) {
              localUserData.push(userData);
            }
          });

          setUsers(localUserData.sort((a, b) => a.name.localeCompare(b.name)));
        });

        return () => {
          unsubscribeUser();
        };
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    };

    fetchUsers();
  }, [registrationType, parsedRegistrationType]);

  return (
    <Container size='4' align='center' className='mb-5 md:mb-9'>
      <Flex
        direction='column'
        align='center'
        gap={{ initial: '6', md: '9' }}
        className='text-center'
      >
        <h1 className='text-xl md:text-3xl font-bold'>
          {parsedRegistrationType}
        </h1>

        <UsersTable
          userData={users}
          registrationType={parsedRegistrationType}
        />
      </Flex>
    </Container>
  );
};

export default QbDRegistrationTypes;
