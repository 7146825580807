import { useEffect, useState } from 'react';
import { Container, Tabs } from '@radix-ui/themes';
import { collection, onSnapshot, doc } from 'firebase/firestore';

import { firestore } from '../services/firebase';
import SteamScanner from '../components/steam/SteamScanner';
import SteamDashboard from '../components/steam/SteamDashboard';

const Steam = () => {
  const [eventData, setEventData] = useState([]);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventsCollection = collection(firestore, 'events');
        const eventDoc = doc(eventsCollection, 'steam');

        const unsubscribeEvent = onSnapshot(eventDoc, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const localEventData = docSnapshot.data();

            setEventData(
              Object.entries(localEventData)
                .sort((a, b) => a[1].title.localeCompare(b[1].title))
                .flatMap((innerArrays) => innerArrays.slice(1))
            );
          }
        });

        return () => {
          unsubscribeEvent();
        };
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    };

    const fetchUserData = async () => {
      try {
        const eventsCollection = collection(firestore, 'steamregistrations');
        const unsubscribeUser = onSnapshot(
          eventsCollection,
          (querySnapshot) => {
            const localUserData = [];
            querySnapshot.forEach((doc) => {
              localUserData.push(doc.data());
            });
            setUserData(localUserData);
          }
        );

        return () => {
          unsubscribeUser();
        };
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    };

    fetchEventData();
    fetchUserData();
  }, []);

  return (
    <Container size='4' align='center'>
      <Tabs.Root
        defaultValue='dashboard'
        className='flex flex-col items-center w-full h-full gap-5 md:gap-9 mb-5 md:mb-9'
      >
        <Tabs.List className='text-xl'>
          <Tabs.Trigger value='dashboard'>Dashboard</Tabs.Trigger>
          {/* Event concluded, enable when needed */}
          {/* <Tabs.Trigger value='scanner'>
            Scanner
          </Tabs.Trigger> */}
        </Tabs.List>

        <Tabs.Content value='dashboard' className='w-full h-full'>
          <SteamDashboard eventData={eventData} userData={userData} />
        </Tabs.Content>

        <Tabs.Content value='scanner' className='w-full h-full'>
          <SteamScanner eventData={eventData} />
        </Tabs.Content>
      </Tabs.Root>
    </Container>
  );
};

export default Steam;
