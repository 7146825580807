import { Check, Minus } from 'lucide-react';
import { Flex, AlertDialog, Table } from '@radix-ui/themes';

import Button from '../Button';

const UserInfo = ({ user }) => (
  <AlertDialog.Root>
    <AlertDialog.Trigger>
      <p>{user.name}</p>
    </AlertDialog.Trigger>
    <AlertDialog.Content className='flex flex-col items-center gap-5 text-center max-w-fit p-10'>
      <AlertDialog.Title>{user.name}</AlertDialog.Title>

      <AlertDialog.Description>
        <Flex
          direction='column'
          align='start'
          gap='2'
          className='text-base md:text-lg'
        >
          <p>
            <strong>Email: </strong>
            {user.email}
          </p>
          <p>
            <strong>Phone: </strong>
            {user.phone}
          </p>
          <p>
            <strong>Grade: </strong>
            {user.grade}
          </p>
          <p>
            <strong>Guests: </strong>
            {user.guests}
          </p>
          <p>
            <strong>Schol: </strong>
            {user.schoolName}
          </p>
        </Flex>
      </AlertDialog.Description>

      <AlertDialog.Cancel>
        <Button
          className='bg-[#B6554F] text-base md:text-lg px-16'
          title='Close'
        />
      </AlertDialog.Cancel>
    </AlertDialog.Content>
  </AlertDialog.Root>
);

const UsersTable = ({ users, event }) => {
  return (
    <Table.Root size='3' variant='surface' className='w-full h-full'>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell className='text-sm'>
            Name
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell className='text-sm'>
            Grade
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell className='text-sm'>
            Attended
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell className='text-sm'>
            School
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {users.map((user, index) => (
          <Table.Row key={index}>
            <Table.RowHeaderCell className='text-sm cursor-pointer'>
              <UserInfo user={user} />
            </Table.RowHeaderCell>
            <Table.Cell className='text-sm'>{user.grade}</Table.Cell>
            <Table.Cell className='text-sm'>
              {user.attendance_done &&
              user.attendance_done.includes(event.id) ? (
                <Check color='#6aec13' />
              ) : (
                <Minus color='#aa1313' />
              )}
            </Table.Cell>
            <Table.Cell className='text-sm'>{user.schoolName}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default UsersTable;
