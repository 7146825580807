import { useEffect, useState } from 'react';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';

const Chart = ({ userData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let localData = [
      {
        day: 'Day 1',
        registered: 0,
        attended: 0,
      },
      {
        day: 'Day 2',
        registered: 0,
        attended: 0,
      },
      {
        day: 'Day 3',
        registered: 0,
        attended: 0,
      },
      {
        day: 'Day 4',
        registered: 0,
        attended: 0,
      },
    ];
    const daysToCheck = [24, 25, 26, 27];

    daysToCheck.forEach((day) => {
      userData.forEach((user) => {
        if (user?.selectedDays?.includes(day)) {
          localData[day - 24].registered++;
        }
        if (user?.attendance_done?.includes(String(day))) {
          localData[day - 24].attended++;
        }
      });
    });

    setData(localData);
  }, [userData]);

  // TODO : use userData to update data

  return (
    <ResponsiveContainer className='min-h-[300px] md:min-h-[400px] w-full flex flex-row items-center justify-center bg-[#fdfdfd] rounded-xl pt-5 pr-5 text-sm md:text-lg'>
      <BarChart data={data} className='-ml-10 md:-ml-5'>
        <XAxis dataKey='day' fontSize='10px md:20px' />
        <YAxis />
        <Tooltip
          contentStyle={{
            maxWidth: '200px',
            overflow: 'hidden',
            textAlign: 'left',
            objectFit: 'contain',
            fontSize: '15px',
          }}
        />
        <Bar dataKey='registered' fill='#82ca9d' />
        <Bar dataKey='attended' fill='#8884d8' />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
