import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';
import { collection, onSnapshot } from 'firebase/firestore';

import { firestore } from '../services/firebase';
import UsersTable from '../components/rday/UsersTable';

const RDayCategory = () => {
  let localData = [
    {
      id: 'invited-speaker',
      title: 'Invited Speaker',
    },
    {
      id: 'panel-member',
      title: 'Panel Member',
    },
    {
      id: 'expert',
      title: 'Expert/Examiner/Collaborator',
    },
    {
      id: 'presenter-bpdc',
      title: 'Presenter - BPDC',
    },
    {
      id: 'presenter-external',
      title: 'Presenter - External',
    },
    {
      id: 'startup',
      title: 'Startup',
    },
    {
      id: 'visitor',
      title: 'Visitor',
    },
  ];
  const { category } = useParams();
  const [users, setUsers] = useState([]);
  const parsedCategory = localData.find(
    (categoryObject) => categoryObject.id === category
  )?.title;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(
          firestore,
          'registrations/2024/rday'
        );
        const unsubscribeUser = onSnapshot(usersCollection, (querySnapshot) => {
          const localUserData = [];
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            if (userData.category.includes(parsedCategory)) {
              localUserData.push(userData);
            }
          });

          setUsers(localUserData.sort((a, b) => a.name.localeCompare(b.name)));
        });

        return () => {
          unsubscribeUser();
        };
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    };

    fetchUsers();
  }, [category, parsedCategory]);

  return (
    <Container size='4' align='center' className='mb-5 md:mb-9'>
      <Flex
        direction='column'
        align='center'
        gap={{ initial: '6', md: '9' }}
        className='text-center'
      >
        <h1 className='text-xl md:text-3xl font-bold'>{parsedCategory}</h1>

        <UsersTable userData={users} category={parsedCategory} />
      </Flex>
    </Container>
  );
};

export default RDayCategory;
