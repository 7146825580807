import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './pages/Login';
import Jashn from './pages/Jashn';
import Steam from './pages/Steam';
import Landing from './pages/Landing';
import Layout from './components/Layout';
import Loading from './components/Loading';
import SteamEvent from './pages/SteamEvent';
import RDayCategory from './pages/RDayCategory';
import RDay from './pages/RDay';
import ProtectedRoute from './routes/ProtectedRoute';
import UnprotectedRoute from './routes/UnprotectedRoute';
import QbD from './pages/QbD';
import QbDRegistrationTypes from './pages/QbDRegitrationTypes';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path='/login'
            element={
              <UnprotectedRoute>
                <Suspense fallback={<Loading />}>
                  <Layout>
                    <Login />
                  </Layout>
                </Suspense>
              </UnprotectedRoute>
            }
          />
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                  <Layout>
                    <Landing />
                  </Layout>
                </Suspense>
              </ProtectedRoute>
            }
          />

          {/* Steam */}
          <Route
            path='/steam'
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                  <Layout>
                    <Steam />
                  </Layout>
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path='/steam/:event'
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                  <Layout>
                    <SteamEvent />
                  </Layout>
                </Suspense>
              </ProtectedRoute>
            }
          />

          {/* Jashn */}
          <Route
            path='/jashn'
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                  <Layout>
                    <Jashn />
                  </Layout>
                </Suspense>
              </ProtectedRoute>
            }
          />

          {/* Research Day */}
          <Route
            path='/researchDay'
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                  <Layout>
                    <RDay />
                  </Layout>
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path='/researchDay/:category'
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                  <Layout>
                    <RDayCategory />
                  </Layout>
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path='/qbd'
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                  <Layout>
                    <QbD />
                  </Layout>
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path='/qbd/:registrationType'
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                  <Layout>
                    <QbDRegistrationTypes />
                  </Layout>
                </Suspense>
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
