import { useState } from 'react';
import { Table } from '@radix-ui/themes';
import { toTitleCase } from '../../helper/helper_functions';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { toast } from 'sonner';

const UsersTable = ({ userData }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredUserData = userData.filter((user) => {
    const searchTerm = searchQuery?.toLowerCase();
    return (
      user.category?.toLowerCase().includes(searchTerm) ||
      user.name?.toLowerCase().includes(searchTerm) ||
      user.email?.toLowerCase().includes(searchTerm) ||
      user.emiratesId?.toLowerCase().includes(searchTerm) ||
      user.universityName?.toLowerCase().includes(searchTerm) ||
      user.uniId?.toLowerCase().includes(searchTerm) ||
      user.selectedDays.some((day) => String(day).includes(searchTerm))
    );
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className='w-full h-full'>
      <input
        type='text'
        placeholder='Search...'
        value={searchQuery}
        onChange={handleSearchChange}
        className='w-full px-3 py-2 mb-4 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
      />

      <Table.Root size='3' variant='surface' className='w-full'>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell className='text-sm'>
              QR Code
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className='text-sm'>
              Resend Email
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className='text-sm'>
              Category
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className='text-sm'>
              Visiting Days
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className='text-sm'>
              Name
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className='text-sm'>
              Email
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className='text-sm'>
              Emirates ID
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className='text-sm'>
              University
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className='text-sm'>
              University ID
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell className='text-sm'>
              Attended Days
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredUserData.map((user, index) => (
            <Table.Row key={index}>
              <Table.Cell className='text-sm'>
                {user?.imageUrl ? (
                  <img
                    className='w-full h-auto'
                    alt='qr'
                    src={user?.imageUrl}
                  />
                ) : (
                  'No QR'
                )}
              </Table.Cell>
              <Table.Cell className='text-sm'>
                <Link
                  className='text-blue-600'
                  onClick={async () => {
                    const formDataWithImageUrl = {
                      name: user?.name,
                      email: user?.email,
                      emiratesId: user?.emiratesId,
                      selectedDays: user?.selectedDays,
                      category: user?.category,
                      uniId: user.uniId?.toUpperCase(),
                      universityName: user?.universityName?.toUpperCase(),
                      imageUrl: user?.imageUrl,
                    };
                    await emailjs
                      .send(
                        'service_t8clwjo',
                        'template_oukpjij',
                        formDataWithImageUrl,
                        'V2Yz1dwEESLY22zXz'
                      )
                      .then(() => {
                        toast('Email sent successfully');
                      });
                  }}
                >
                  Resend Email
                </Link>
              </Table.Cell>
              <Table.Cell className='text-sm'>
                {toTitleCase(user?.category?.toString())}
              </Table.Cell>
              <Table.Cell className='text-sm'>
                <div className='flex flex-row items-center gap-2'>
                  {user.selectedDays.map((d) => (
                    <p key={d}>{d}th</p>
                  ))}
                </div>
              </Table.Cell>
              <Table.Cell className='text-sm'>{user.name}</Table.Cell>
              <Table.Cell className='text-sm'>{user.email}</Table.Cell>
              <Table.Cell className='text-sm'>{user.emiratesId}</Table.Cell>
              <Table.Cell className='text-sm'>
                <p className='line-clamp-1 hover:line-clamp-none'>
                  {user.universityName}
                </p>
              </Table.Cell>
              <Table.Cell className='text-sm'>
                <p className='line-clamp-1 hover:line-clamp-none'>
                  {user.uniId}
                </p>
              </Table.Cell>
              <Table.Cell className='text-sm'>
                <div className='flex flex-row items-center gap-2 '>
                  {user.attendance_done?.map((d) => (
                    <p key={d} className='line-clamp-1 hover:line-clamp-none'>
                      {d}th
                    </p>
                  ))}
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  );
};

export default UsersTable;
