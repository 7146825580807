import { Link } from 'react-router-dom';
import { Container, Flex } from '@radix-ui/themes';

import Button from '../components/Button';

const Landing = () => {
  return (
    <Container
      size='3'
      className='flex items-center justify-center h-dvh -mt-20 md:-mt-8'
    >
      <Flex
        direction='column'
        align='center'
        justify='center'
        gap={{ initial: '4', md: '8' }}
      >
        <h1 className='limelight-regular text-4xl md:text-6xl text-center'>
          Select Event
        </h1>

        <Flex
          direction='row'
          align='center'
          justify='center'
          gap={{ initial: '4', md: '8' }}
          className='flex-wrap w-full md:w-auto'
        >
          <Link to='/qbd'>
            <Button
              title='QbD'
              className='text-xl md:text-4xl py-5 md:py-8 px-10 md:px-20'
            />
          </Link>
          <Link to='/researchday'>
            <Button
              title='Research Day'
              className='text-xl md:text-4xl py-5 md:py-8 px-10 md:px-20'
            />
          </Link>

          <Link to='/jashn'>
            <Button
              title='Jashn'
              className='text-xl md:text-4xl py-5 md:py-8 px-10 md:px-20'
            />
          </Link>

          <Link to='/steam'>
            <Button
              title='Steam'
              className='text-xl md:text-4xl py-5 md:py-8 px-10 md:px-20'
            />
          </Link>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Landing;
