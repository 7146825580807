export const categoryMapping = [
  {
    label: 'Invited Speaker',
    fields: [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phone',
        label: 'Phone',
      },
      {
        name: 'designation',
        label: 'Designation',
      },
      {
        name: 'organization',
        label: 'Organization',
      },
      {
        name: 'attendance_done',
        label: 'Attended',
      },
    ],
  },
  {
    label: 'Panel Member',
    fields: [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phone',
        label: 'Phone',
      },
      {
        name: 'designation',
        label: 'Designation',
      },
      {
        name: 'organization',
        label: 'Organization',
      },
      {
        name: 'attendance_done',
        label: 'Attended',
      },
    ],
  },
  {
    label: 'Expert/Examiner/Collaborator',
    fields: [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phone',
        label: 'Phone',
      },
      {
        name: 'designation',
        label: 'Designation',
      },
      {
        name: 'organization',
        label: 'Organization',
      },
      {
        name: 'attendance_done',
        label: 'Attended',
      },
    ],
  },
  {
    label: 'Presenter - BPDC',
    fields: [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'id',
        label: 'ID',
      },
      {
        name: 'department',
        label: 'Department',
      },
      {
        name: 'posterNo',
        label: 'Poster No.',
      },
      {
        name: 'posterTitle',
        label: 'Poster Title',
      },
      {
        name: 'posterUrl',
        label: 'Poster',
      },
      {
        name: 'videoUrl',
        label: 'Video',
      },
      {
        name: 'attendance_done',
        label: 'Attended',
      },
    ],
  },
  {
    label: 'Presenter - External',
    fields: [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'id',
        label: 'ID',
      },
      {
        name: 'department',
        label: 'Department',
      },
      {
        name: 'posterNo',
        label: 'Poster No.',
      },
      {
        name: 'posterTitle',
        label: 'Poster Title',
      },
      {
        name: 'posterUrl',
        label: 'Poster',
      },
      {
        name: 'videoUrl',
        label: 'Video',
      },
      {
        name: 'attendance_done',
        label: 'Attended',
      },
    ],
  },
  {
    label: 'Startup',
    fields: [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phone',
        label: 'Phone',
      },
      {
        name: 'designation',
        label: 'Designation',
      },
      {
        name: 'organization',
        label: 'Startup Name',
      },
      {
        name: 'address',
        label: 'Address',
      },
      {
        name: 'attendance_done',
        label: 'Attended',
      },
    ],
  },
  {
    label: 'Visitor',
    fields: [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phone',
        label: 'Phone',
      },
      {
        name: 'organization',
        label: 'Organization',
      },
      {
        name: 'attendance_done',
        label: 'Attended',
      },
    ],
  },
];
