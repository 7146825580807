import { useEffect, useState } from 'react';
import { Card, Grid, Flex } from '@radix-ui/themes';

import Chart from './Chart';
import UsersTable from './UsersTable';

const JashnDashboard = ({ userData }) => {
  const [totalRegistered, setTotalRegistered] = useState(0);
  const [totalAttended, setTotalAttended] = useState(0);

  useEffect(() => {
    let totalRegistered = 0;
    let totalAttended = 0;

    userData.forEach((user) => {
      totalRegistered += 1;
      totalAttended += user.attendance_done?.length > 0 ? 1 : 0;
    });

    setTotalRegistered(totalRegistered);
    setTotalAttended(totalAttended);
  }, [userData]);

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      width='100%'
      height='100%'
      gap={{ initial: '3', md: '5' }}
    >
      <Grid
        columns={{ initial: '1', md: '4' }}
        align='ceter'
        justify='center'
        gap={{ initial: '3', md: '5' }}
        width='100%'
      >
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Registrations</p>
          <p className='text-base md:text-lg font-semibold'>
            {totalRegistered}
          </p>
        </Card>
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Attended</p>
          <p className='text-base md:text-lg font-semibold'>{totalAttended}</p>
        </Card>
      </Grid>

      <Chart userData={userData} />

      <UsersTable userData={userData} />
    </Flex>
  );
};

export default JashnDashboard;
