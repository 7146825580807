export const categoryMapping = [
  {
    label: 'Individual',
    fields: [
      {
        name: 'title',
        label: 'Title',
      },
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'department',
        label: 'Department',
      },
      {
        name: 'organization',
        label: 'Organization / Institution',
      },
      {
        name: 'organizationType',
        label: 'Organization Type',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'mobile',
        label: 'Mobile Number',
      },
      {
        name: 'participantType',
        label: 'Participant Type',
      },
      {
        name: 'requirements',
        label: 'Special Requirements',
      },
      { name: 'emergencyName', label: 'Name' },
      { name: 'emergencyRelation', label: 'Relation' },
      { name: 'emergencyMobile', label: 'Mobile Number' },
      {
        name: 'proofOfTransactionUrl',
        label: 'Proof Url',
      },
      {
        name: 'attendance_done',
        label: 'Attended',
      },
    ],
  },
  {
    label: 'Group',
    fields: [
      { name: 'participantOneTitle', label: 'Participant One Title' },
      { name: 'participantOneName', label: 'Participant One Full Name' },
      { name: 'participantOneEmail', label: 'Participant One Email' },
      { name: 'participantOneMobile', label: 'Participant One Mobile Number' },
      { name: 'participantTwoTitle', label: 'Participant Two Title' },
      { name: 'participantTwoName', label: 'Participant Two Full Name' },
      { name: 'participantTwoEmail', label: 'Participant Two Email' },
      { name: 'participantTwoMobile', label: 'Participant Two Mobile Number' },
      { name: 'participantThreeTitle', label: 'Participant Three Title' },
      { name: 'participantThreeName', label: 'Participant Three Full Name' },
      { name: 'participantThreeEmail', label: 'Participant Three Email' },
      {
        name: 'participantThreeMobile',
        label: 'Participant Three Mobile Number',
      },
      { name: 'organization', label: 'Organization / Institution' },
      { name: 'organizationType', label: 'Organization Type' },
      { name: 'organizationContactName', label: 'Contact Name' },
      { name: 'organizationContactRelation', label: 'Designation' },
      { name: 'organizationContactEmail', label: 'Organization Email' },
      {
        name: 'organizationContactMobile',
        label: 'Organization Mobile Number',
      },
      {
        name: 'requirements',
        label: 'Special Requirements (Dietary, Accessibility, etc.)',
      },
      { name: 'proofOfTransactionUrl', label: 'Proof Url' },
    ],
  },
];
