import { fetchDocumentByHashValue } from './firebase_functions';
import { arrayUnion, updateDoc, doc } from 'firebase/firestore';
import { firestore } from '../services/firebase';

export const checkSteamAttendance = async (hashedId, eventId) => {
  const documentData = await fetchDocumentByHashValue({
    providedValue: hashedId,
    collectionPath: 'steamregistrations',
  });

  if (documentData && documentData.events) {
    const { events, emiratesId } = documentData;
    const docRef = doc(firestore, `steamregistrations/${emiratesId}`);

    if (eventId === 'mainentrance') {
      await updateDoc(docRef, {
        attendance_done: arrayUnion(eventId),
      });
      return [true, `User is registered for STEAM@BITS2024`, documentData];
    }
    //TODO remove if we dont want single time pass
    // if (attendance_done && attendance_done.includes(eventId)) {
    //   return [true, "User has already attended this event", documentData];
    // }

    if (events.includes(eventId)) {
      await updateDoc(docRef, {
        attendance_done: arrayUnion(eventId),
      });
      return [
        true,
        `User is registered for ${eventId}. Attendance taken`,
        documentData,
      ];
    } else {
      return [
        false,
        `User found but no registration for ${eventId}`,
        documentData,
      ];
    }
  } else {
    return [false, 'Not registered for STEAM', documentData];
  }
};

export const checkJashnAttendance = async (hashedId, day) => {
  const documentData = await fetchDocumentByHashValue({
    providedValue: hashedId,
    collectionPath: 'registrations/2024/jashn',
  });

  if (documentData) {
    const { emiratesId } = documentData;
    const docRef = doc(firestore, `registrations/2024/jashn/${emiratesId}`);

    await updateDoc(docRef, {
      attendance_done: arrayUnion(day),
    });
    return [
      true,
      `User is registered for ${day}th. Attendance taken`,
      documentData,
    ];
  } else {
    return [false, 'Not registered for JASHN', {}];
  }
};
export const checkRDayAttendance = async (hashedId) => {
  const documentData = await fetchDocumentByHashValue({
    providedValue: hashedId,
    collectionPath: 'registrations/2024/rday',
  });

  if (documentData) {
    const { hashValue } = documentData;
    const docRef = doc(firestore, `registrations/2024/rday/${hashValue}`);

    await updateDoc(docRef, {
      attendance_done: true,
    });
    return [true, 'Attendance taken', documentData];
  } else {
    return [false, 'Not registered for Research Day', {}];
  }
};
