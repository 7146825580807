import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../services/firebase';

export const fetchDocumentByHashValue = async ({
  providedValue,
  collectionPath,
}) => {
  try {
    const q = query(
      collection(firestore, collectionPath),
      where('hashValue', '==', providedValue)
    );

    // Fetch the documents that match the query
    const querySnapshot = await getDocs(q);

    // Check if any documents were found
    if (!querySnapshot.empty) {
      // Document(s) found, return the first document's data
      const documentData = querySnapshot.docs[0].data();
      //   console.log('Document data:', documentData);
      return documentData;
    } else {
      // console.log('No documents found matching the provided value.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching document:', error);
    return null;
  }
};
