export const Values = {
  encryptionKey: '6Ldl0FYpAAAAAIVfMusch-kV38Sj-AVXbsxpLJVy',
  emiratesIdRegex: /^784\d{12}$/,
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  events: ['steam'],
};
