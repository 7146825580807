import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import { Flex, Box, AlertDialog, Button } from '@radix-ui/themes';

import { checkRDayAttendance } from '../../helper/attendance';
import { categoryMapping } from '../../constants/researchDay';

function RDayScanner({ userData }) {
  const [showAlert, setShowAlert] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      gap='5'
      width='100%'
      height='100%'
    >
      <Box className='w-full md:w-[50%]'>
        <Scanner
          options={{ delayBetweenScanSuccess: 1000 }}
          styles={{
            container: {
              border: 'none',
            },
            finderBorder: 10,
          }}
          onResult={async (hashedId) => {
            const [attendanceResult, message, userDetails] =
              await checkRDayAttendance(hashedId);

            setUserDetails(userDetails);

            if (attendanceResult === true) {
              toast.success(message);
              setShowAlert(true);
              // console.log(message);
            } else {
              toast.error(message);
              console.error(message);
            }
          }}
          onError={(error) => console.error(error?.message)}
        />
      </Box>

      <AlertDialog.Root open={showAlert}>
        <AlertDialog.Content className='w-auto p-10 flex flex-col align-center gap-5'>
          <AlertDialog.Title className='text-center'>Details</AlertDialog.Title>
          <AlertDialog.Description asChild>
            <Flex direction='column' align='start' gap='5'>
              {categoryMapping
                .find((map) => map.label === userDetails?.category)
                ?.fields.map((field, index) => (
                  <p key={index} className='text-sm'>
                    <strong>{field.label}</strong>:{' '}
                    {!userDetails?.[`${field.name}`] ? (
                      '--'
                    ) : field.name.includes('Url') ? (
                      <Link
                        to={userDetails?.[`${field.name}`]}
                        className='text-blue-600'
                      >
                        Download
                      </Link>
                    ) : (
                      userDetails?.[`${field.name}`]?.toString()
                    )}
                  </p>
                ))}
            </Flex>
          </AlertDialog.Description>
          <Flex gap='3' mt='5' justify='center'>
            <AlertDialog.Cancel>
              <Button
                variant='solid'
                onClick={() => setShowAlert(false)}
                className='text-black px-9 bg-[#fdfdfd] rounded-lg cursor-pointer'
              >
                Close
              </Button>
            </AlertDialog.Cancel>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </Flex>
  );
}

export default RDayScanner;
