import { useState } from 'react';
import { Table } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { categoryMapping } from '../../constants/qbd';

const UsersTable = ({ userData, registrationType }) => {
  const [searchQuery, setSearchQuery] = useState('');

  //   const filteredUserData = userData.filter((user) => {
  //     const searchTerm = searchQuery?.toLowerCase();
  //     return (
  //       user.name?.toLowerCase().includes(searchTerm) ||
  //       user.email?.toLowerCase().includes(searchTerm)
  //     );
  //   });

  const filteredUserData = userData;

  //   console.log(filteredUserData);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className='w-full h-full'>
      <input
        type='text'
        placeholder='Search...'
        value={searchQuery}
        onChange={handleSearchChange}
        className='w-full px-3 py-2 mb-4 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
      />

      <Table.Root size='3' variant='surface' className='w-full'>
        <Table.Header>
          <Table.Row>
            {/* <Table.ColumnHeaderCell className='text-sm'>
              QR
            </Table.ColumnHeaderCell> */}
            {categoryMapping
              .find((map) => map.label === registrationType)
              ?.fields.map((field, index) => (
                <Table.ColumnHeaderCell key={index} className='text-sm'>
                  {field.label}
                </Table.ColumnHeaderCell>
              ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredUserData?.map((user, index) => (
            <Table.Row key={index}>
              {/* <Table.Cell className='text-sm'>
                {user?.qrCodeDownloadUrl ? (
                  <img
                    className='w-full h-auto'
                    alt='qr'
                    src={user?.qrCodeDownloadUrl}
                  />
                ) : (
                  'No QR'
                )}
              </Table.Cell> */}

              {categoryMapping
                .find((map) => map.label === registrationType)
                .fields.map((field, index) => (
                  <Table.Cell key={index} className='text-sm'>
                    {!user?.[`${field.name}`] ? (
                      '--'
                    ) : field.name.includes('Url') ? (
                      <Link
                        to={user?.[`${field.name}`]}
                        className='text-blue-600'
                      >
                        Download
                      </Link>
                    ) : (
                      user?.[`${field.name}`]?.toString()
                    )}
                  </Table.Cell>
                ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  );
};

export default UsersTable;
