import { Link } from 'react-router-dom';
import { Table } from '@radix-ui/themes';

const RegistrationTypesTable = ({ userData, data }) => {
  return (
    <Table.Root size='3' variant='surface' className='w-full h-full'>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell className='text-sm'>
            Type
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell className='text-sm'>
            Total Participants
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map((event, index) => (
          <Table.Row key={index}>
            <Table.RowHeaderCell className='text-sm'>
              <Link to={event.id}>{event.title}</Link>
            </Table.RowHeaderCell>
            <Table.Cell className='text-sm'>{event.registered}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default RegistrationTypesTable;
