import { useEffect, useState } from 'react';
import { Container, Tabs } from '@radix-ui/themes';
import { collection, onSnapshot } from 'firebase/firestore';

import { firestore } from '../services/firebase';
import Dashboard from '../components/rday/Dashboard';
import RDayScanner from '../components/rday/RDayScanner';

const RDay = () => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const eventsCollection = collection(
          firestore,
          'registrations/2024/rday'
        );

        const unsubscribeUser = onSnapshot(
          eventsCollection,
          (querySnapshot) => {
            const localUserData = [];
            querySnapshot.forEach((doc) => {
              localUserData.push(doc.data());
            });

            setUserData(localUserData);
          }
        );

        return () => {
          unsubscribeUser();
        };
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Container size='4' align='center'>
      <Tabs.Root
        defaultValue='dashboard'
        className='flex flex-col items-center w-full h-full gap-5 md:gap-9 mb-5 md:mb-9'
      >
        <Tabs.List className='text-xl'>
          <Tabs.Trigger value='dashboard'>Dashboard</Tabs.Trigger>
          <Tabs.Trigger value='scanner'>Scanner</Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value='dashboard' className='w-full h-full'>
          <Dashboard userData={userData} />
        </Tabs.Content>

        <Tabs.Content value='scanner' className='w-full h-full'>
          <RDayScanner userData={userData} />
        </Tabs.Content>
      </Tabs.Root>
    </Container>
  );
};

export default RDay;
